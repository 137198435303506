<template>
  <div v-if="source">
    <action-header :actions-model="headerModel" />

    <div
      class="q-pa-md"
      style="min-height: 73vh;"
    >
      <form-builder :schema="schema" />
    </div>

    <div class="row justify-center items-center q-pa-sm border-top">
      <q-btn
        color="dark"
        text-color="white"
        size="sm"
        class="q-mr-sm"
        :label="$t('Back')"
        no-caps
        unelevated
        @click="handleBack"
      />

      <q-btn
        color="light-blue-9"
        text-color="white"
        size="sm"
        :label="$t('Save')"
        no-caps
        unelevated
        @click="save"
      />
    </div>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import ActionHeader from '../../components/action-header/ActionHeader.vue'

export default {
  name: 'Shopify',
  components: {
    ActionHeader
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'source'
    ]),
    headerModel () {
      return [
        {
          section: 'BackAction',
          className: 'col-sm-1 p-l-15 hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'col-sm-3 mobile-title',
          options: [
            {
              id: 'title',
              type: 'text',
              value: this.$t('Shopify Integration') + ': ' + (this.source || {}).id
            }
          ]
        }
      ]
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Name'),
                field: 'name',
                value: this.source.name,
                wrapperStyleClasses: 'col-6',
                onChange: name => {
                  this.updateSource({ name })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('URL'),
                field: 'url',
                value: this.source.settings.url,
                wrapperStyleClasses: 'col-6',
                onChange: url => {
                  this.updateSource({ settings: { ...this.source.settings, url } })
                }
              }
            ]
          }
        ]
      }
    }
  },
  mounted () {
    this.loadSource(this.$route.params.id)
  },
  methods: {
    ...mapMutations([
      'updateSource'
    ]),
    ...mapActions([
      'loadSource'
    ]),
    handleBack () {
      this.$router.back()
    },
    save () {}
  }
}
</script>
